.img-cl-sto {
  width: 80%;
  margin: auto;
  display: block;
}

.area-text-cs {
  word-wrap: break-word;
}

.box-text-section {
  // width: 60%;
  width: 100%;
  display: grid;
  max-width: 1440px;
  grid-column-gap: 2.5rem;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
}

.area-text-section {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  align-items: center;
  grid-row-end: 2;
  align-content: center;
  grid-row-start: 1;
  grid-column-end: 8;
  justify-content: flex-start;
  grid-column-start: 1;
}

.title-insights-section {
  color: #f6f6f6;
  font-size: 4rem;
}

.insights-desc-box {
  flex: 1;
  width: 100%;
  display: grid;
  max-width: 1440px;
  grid-column-gap: 2.5rem;
  grid-template-rows: auto;
  grid-template-columns: repeat(12, 1fr);
}

.box-line-insights-desc {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  align-items: flex-start;
  grid-row-end: 2;
  align-content: flex-start;
  grid-row-start: 1;
  grid-column-end: 2;
  justify-content: flex-start;
  grid-column-start: 1;
}

.line-insights-desc {
  color: #5b5f61;
  max-width: 48px;
  min-width: 48px;
  border-top: 1px solid rgba(21, 31, 39, 1);
  box-shadow: none;
  margin-top: 13px;
  border-left: none;
  margin-left: 0px;
  padding-top: 0px;
  border-right: none;
  margin-right: 0px;
  padding-left: 0px;
  border-bottom: none;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  text-transform: none;
  text-decoration: none;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 1px solid rgba(255, 255, 255, 1);
  border-left: none;
  border-right: none;
  border-bottom: none;
}

.box-text-insights-desc {
  display: flex;
  flex-wrap: wrap;
  min-width: 0;
  align-items: flex-start;
  grid-row-end: 2;
  align-content: flex-start;
  grid-row-start: 1;
  grid-column-end: 9;
  justify-content: flex-start;
  grid-column-start: 2;
}

.box-format-text-insights {
  position: relative;
  word-wrap: break-word;
  white-space: break-spaces;
}

@media (max-width: 480px) {
  .area-text-section {
    grid-row-end: 2;
    grid-row-start: 1;
    grid-column-end: 8;
    grid-column-start: 1;
  }
  .box-text-section {
    max-width: 576px;
    grid-template-columns: repeat(4, 1fr);
  }
  .title-insights-section {
    font-size: 2.5rem;
  }
  .box-line-insights-desc {
    display: none;
  }
  .box-text-insights-desc {
    grid-column-end: 5;
    grid-column-start: 1;
  }
  .insights-desc-box {
    max-width: 576px;
    grid-template-columns: repeat(4, 1fr);
  }
}
