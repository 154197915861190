.lbl-404 {
  font-size: 13rem;
}

.btn-head-home {
  background-color: var(--sydBlack);
  color: var(--sydWhite);
  &:hover {
    color: var(--sydYellow);
  }
}
