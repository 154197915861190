.syd-chips {
  border: 1px solid #141414;
  cursor: pointer;
  &.active {
    background-color: #141414;
    color: #fece2f;
  }
  &:hover {
    background-color: #e8e8e8;
    &.active {
      background-color: #141414;
      color: #fece2f;
    }
  }
}

.btn-clear-filter {
  border: 1px solid #141414;
  color: #f6f6f6;
  background-color: #141414;
  width: max-content;
  cursor: pointer;
}

.cards-cl-sto{
  aspect-ratio: 1/1;
}
