@import "~bootstrap/scss/bootstrap";
@import url("https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap");
@import "/node_modules/flag-icons/css/flag-icons.min.css";

:root {
  // --sydYellow: #fece2f;
  // --sydWhite: #f6f6f6;
  // --sydBlack: #141414;
  // --lightBg: #fafafa;
  --opacityDarkBg: #000000b8;

  --syd-dark-color: #141414;

  // --primary-color: #3498db;
  // --secondary-color: #2ecc71;

  --brand-color-main: #ff0000;
  --brand-color-secondary: #fff;
  --brand-color-details: #000;

  --light-mode-title: #ff0000;
  --light-mode-text: #333;
  --light-mode-background: #f5f5f5;
  --light-mode-btn-background: #000;
  --light-mode-btn-text: #777ccc;

  --dark-mode-title: #ff0000;
  --dark-mode-text: #fff;
  --dark-mode-background: #000;
  --dark-mode-btn-background: #ff0000;
  --dark-mode-btn-text: #000;
}

body {
  margin: 0;
  font-family: "Play", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url(../src/assets/hero_blue.png);
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: cover;
  // overscroll-behavior: none;
  // background-repeat: no-repeat;
  // background-position: center center;
  // background-attachment: fixed;
  background-color: #0c0c0c;
}

body:has(.overlay-detail-news) {
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.section-home {
  min-height: 100vh;
  width: 100%;
  &.light {
    background-color: var(--light-mode-background);
  }
  &.dark {
    background-color: var(--dark-mode-background);
  }
}

.dark-mode-text{
  color: var(--dark-mode-text);
}
.dark-mode-title{
  color: var(--dark-mode-title);
}
.dark-mode-bg{
  background-color: var(--dark-mode-background);
}

.light-mode-text{
  color: var(--light-mode-text);
}
.light-mode-bg{
  background-color: var(--light-mode-background);
}
.light-mode-text{
  color: var(--light-mode-text);
}

.bg-main-color {
  background-color: var(--brand-color-main);
}

.bkg-light {
  background-color: var(--light-mode-background);
}

.bkg-dark {
  background-color: var(--dark-mode-background);
}

.bkg-gray {
  background-color: #ededed;
}

.pre-w-space {
  white-space: pre;
}

.pre-line-w-space {
  white-space: pre-line;
}

.syd-box {
  background: #000000ad;
  height: fit-content;
  padding: 4rem 2rem;
  margin: auto;
  border-top: 3px solid var(--brand-color-main);
  &.hero-banner {
    width: 60vw;
    top: 0;
    left: 5vw;
    position: absolute;
    bottom: 0;
  }
  &.small {
    padding: 2rem 1rem;
    transition: all 0.3s ease-in-out;
    height: 100%;
    &:hover {
      background: #000;
      transform: scale(1.02);
    }
  }
  &.flat {
    background: var(--dark-mode-background);
  }
}

.syd-title {
  font-size: 2.3rem;
  &.light {
    color: var(--dark-mode-title);
  }
  &.dark {
    color: #333;
  }
  &.white {
    color: #f5f5f5;
  }
  &.small {
    font-size: 1.5rem;
  }
}

.syd-subtitle {
  color: var(--sydWhite);
  font-size: 1.2rem;
}

.syd-paragraph {
  color: var(--dark-mode-text);
  white-space: pre-line;
  & a {
    text-decoration: none;
    color: var(--brand-color-main);
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #d2a40c;
    }
  }
}

.text-deco-none {
  text-decoration: none;
}

.syd-button {
  border: 2px solid #333;
  background-color: #fff;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-color: #333;
    color: var(--brand-color-main);
  }
  &.btn-main {
    background: var(--brand-color-main);
    &:hover {
      background-color: #333;
      color: var(--brand-color-main);
    }
  }
}
.main-color {
  color: var(--brand-color-main);
}
// .syd-yellow {
//   color: var(--sydYellow);
// }
// .syd-white {
//   color: var(--sydWhite);
// }
// .syd-black {
//   color: var(--sydBlack);
// }

.syd-bg-dark {
  background-color: var(--dark-mode-background);
}

.syd-bg-light {
  background-color: var(--light-mode-background);
}

.transition-03s-eio {
  transition: all 0.3s ease-in-out;
}

.syd-hero {
  min-height: 40vh;
  line-height: normal;
  padding: 0 2rem;
  display: flex;
}

.w-2_3 {
  width: 75%;
}

.w-max-content{
  width: max-content;
}

.main-hero {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.breadcrumb-detail {
  & a:hover {
    color: var(--dark-mode-title);
  }
}

.toolbar-stick-priv{
  position: sticky;
  top: 0;
  z-index: 4;
  background: #f7f7f7;
  filter: drop-shadow(1px 2px 5px #bdbdbdcf);
}

.animated-text-tracking-in{
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
	animation: tracking-in-expand 1s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.animated-scale-in-box{
  -webkit-animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: scale-in-hor-left 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
@-webkit-keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}
@keyframes scale-in-hor-left {
  0% {
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleX(1);
            transform: scaleX(1);
    -webkit-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
    opacity: 1;
  }
}

.disabled-label{
  color: #7d7d7d;
}

.showMobile{
  display: none !important;
}

.showDesktop{
  display: flex !important;
}

.box-fixed-loader{
  position: fixed;
  height: max-content;
  width: max-content;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.loader-sydea{
  width: 100vw;
  height: 100vh;
  position: fixed;
  background-color: #000;
  // transition: all 1s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 2000;
  &.hidden {
    transition: all 1s ease-in-out;
    opacity: 0;
    z-index: -1;
  }
}

.box-loader-intro{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.hero-story-det{
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  min-height: 80vh;
}

@media (max-width: 480px) {
  .syd-box {
    &.hero-banner {
      width: 90vw;
    }
  }
  .w-2_3 {
    width: 100%;
  }
  .order-inverter {
    order: -1;
  }
  .syd-title{
    font-size: 1.9rem;
  }

  .showMobile{
    display: flex !important;
  }
  .showDesktop{
    display: none !important;
  }
}


// .node-foreign-object-div, .node-foreign-object, .node-rect, .node{
//   width: 100px !important;
// }
