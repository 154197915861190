.img-cl-sto {
  width: 80%;
  margin: auto;
  display: block;
}

.area-text-cs {
  word-wrap: break-word;
}

.overlay-area-info {
  // background-color: #00000059;
  // background: linear-gradient(24deg, rgba(0, 0, 0, .35), transparent);
  background: rgba(0, 0, 0, 0.4);
}


.body-text{
  width: 75%;
}

@media (max-width: 480px) {
  .body-text{
    width: 100%;
  } 
}