.cookie-banner {
  width: 500px;
  height: max-content;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;
  gap: 1rem;
  overflow: hidden;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.062);
  position: fixed;
  z-index: 1;
  margin-left: 1rem;
  right: 1rem;
  bottom: 1rem;
  opacity: 1;
  transform: translateY(0);
  visibility: visible !important;
  font-size: 1rem;
}

#cookieSvg {
  width: 50px;
}

#cookieSvg g path {
  fill: rgb(97, 81, 81);
}

.cookie-heading {
  font-size: 1.2em;
  font-weight: 800;
  color: rgb(26, 26, 26);
}

.cookie-description {
  text-align: center;
  font-size: 0.7em;
  font-weight: 600;
  color: rgb(99, 99, 99);
  & span {
    font-size: 2em;
    font-weight: 800;
    color: rgb(26, 26, 26);
  }
  & a{
    color: #fece2f;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    &:hover {
      color: #d9a703;
    }
  }
}

.accept-button {
  transition-duration: 0.3s;
  cursor: pointer;
  border: none;
  background-color: #fece2f;
  color: #141414;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: #111111;
    color: #f5f5f5;
    transition-duration: 0.3s;
  }
}

.decline-button {
  background-color: #ccc;
  transition-duration: 0.3s;
  color: rgb(46, 46, 46);
  cursor: pointer;
  border: none;
  padding: 0.5rem 1rem;
  &:hover {
    background-color: #ebebeb;
    transition-duration: 0.3s;
  }
}

#main-banner-cookie {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  background: transparent;
  position: relative;
  text-rendering: optimizeLegibility;
  z-index: 2147483647;
}

#main-banner-cookie .wrapper-cookie:before {
  background: rgba(0, 0, 0, 0.65);
  bottom: 0;
  content: "";
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
  z-index: 0;
  opacity: 1;
  visibility: visible;
}

.link-cookie-banner {
  color: #fece2f;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #d9a703;
  }
}

@media (max-width: 480px) {
  .cookie-banner {
    width: 100%;
    right: 0;
  }
}
