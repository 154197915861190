.box-certifications{
  border: 2px solid #000;
}

.btn-download-cert{
  cursor: pointer;
  width: max-content;
  &:hover{
    color: var(--sydYellow);
  }
}

.icon-certifications{
  height: 5rem;
  width: auto;
}