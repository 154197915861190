#syd-menu {
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 3rem;

  & li {
    list-style-type: none;
    position: static;
    & .activeNav {
      color: var(--brand-color-main);
    }
  }
  & a {
    color: #000;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    font-size: 1rem;
    &:hover {
      color: var(--brand-color-main);
      .arrow-icon-nav{
        fill: var(--brand-color-main);
      }
    }
  }
  & .arrow-icon-nav{
    width: 10px;
    height: auto;
    fill: var(--sydBlack);
  }
}

.logo-nav {
  width: 100px;
  height: auto;
  transition: all .3s ease-in-out;
}

.logo-nav-indastria {
  width: 130px;
  height: auto;
  transition: all .3s ease-in-out;
}

.nav-box-detail {
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border-top: 1px solid #e3e3e3;
}

#syd-submenu {
  & ul {
    padding-left: .5rem;
  }
  & a {
    text-transform: inherit;
  }
  & > li {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}

.category-submenu {
  text-transform: uppercase;
  font-weight: 600;
}

.primary-nav-col {
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-right: 1rem;
}

.navbar-bg, .offcanvas-header {
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // max-width: 100vw;
}

.syd-logo-arrow {
  fill: #feca21;
}
.syd-logo-main-color {
  fill: #0b0c0c;
}

#main-nav {
  &.navbar-transparent {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid #ffffff63;
    .syd-logo-main-color {
      fill: #f6f6f6;
    }
    .link-feat-top {
      color: var(--brand-color-main) !important;
      &:hover {
        color: var(--sydBlack) !important;
      }
    }
    &:hover {
      background: #fff;
      .syd-logo-main-color {
        fill: var(--sydBlack);
      }
      & a {
        color: inherit;
      }
      .syd-button {
        background-color: inherit;
        color: inherit;
        border-color: #000;
        &:hover {
          background-color: var(--light-mode-btn-background);
          color: var(--brand-color-main);
        }
      }
      .arrow-icon-nav{
        fill: var(--sydBlack);
      }
    }
    & a {
      color: #fff;
      &:hover {
        color: var(--brand-color-main);
        .arrow-icon-nav{
          fill: var(--brand-color-main);
        }
      }
    }
    .syd-button {
      background-color: transparent;
      color: #fff;
      border-color: #fff;
    }
    .arrow-icon-nav{
      fill: #fff;
    }
  }
}

.arrow-icon-nav.rotated {
  transform: rotate(180deg);
}

#main-nav-mob {
  &.navbar-transparent {
    background: transparent;
    box-shadow: none;
    border-bottom: 1px solid #ffffff63;
    .syd-logo-main-color {
      fill: var(--sydWhite);
    }
    .link-feat-top {
      color: var(--brand-color-main) !important;
      &:hover {
        color: var(--sydBlack) !important;
      }
    }
    .hamburger-menu, .color-logo-mob {
      fill: #f7f7f7;
    }
  }
  background: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .syd-logo-main-color {
    fill: var(--sydBlack);
  }
  .hamburger-menu, .color-logo-mob {
    fill: #141414;
  }
}

.detail-menu-services {
  opacity: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  &.show {
    opacity: 1;
  }
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
}

.lt-box-nav-insights {
  border-right: 1px solid #000;
}

.label-sub-nav {
  color: #8f8f8f;
  font-weight: 600;
}

.is-active-navlink {
  color: var(--brand-color-main) !important;
  & .arrow-icon-nav{
    fill: var(--brand-color-main) !important;
  }
}

.nav-industries-list {
  height: 14rem;
  display: flex;
  flex-flow: column wrap;
}

.text-capitalize-menu {
  & a {
    text-transform: capitalize !important;
  }
}

.box-nav-careers {
  width: 50%;
}

.box-nav-careers-img {
  height: auto;
  width: 50%;
  aspect-ratio: 3/1;
  & img {
    object-fit: cover;
    object-position: top;
  }
}

.col-locations {
  flex: 0 1 auto;
}

.col-image-locations {
  flex: 1 1 auto;
}

.hamburger-menu {
  width: 20px;
}
.hamburger-icon {
  fill-rule: evenodd;
}

.separator-item {
  height: 1px;
  background-color: #c8c8c8;
}

.arrow-menu-mob {
  height: 10px;
}

.sub-nav-mob{
  background-color: #fff;
  overflow: auto;
  display: block;
  left: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  & ul{
    list-style: none;
  }
  & a{
    text-decoration: none;
    color: inherit;
  }
}

.nav-menu-item-mob{
  border-bottom: 1px solid #e2e2e2;
  &:last-child{
    border-bottom: none;
  }
  
}
.show-services-menu,
.show-industries-menu,
.show-about-menu,
.show-language-menu,
.show-products-menu,
.show-insights-menu{
  display: block;
  left: 0%;
}

.logo-mob-nav-b{
  width: 30%;
  display: block;
  margin: auto;
  padding-top: 2rem;
  opacity: .5;
}

.offcanvas{width: 100% !important;}

.offcanvas-body {
  width: 100vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer-text{
  font-size: 10px;
  text-align: center;
}

.cover-img-news-menu{
  width: 33.33%;
  height: auto;
  aspect-ratio: 2/1;
  text-transform: inherit !important;
}

.box-img-nav-menu{
  background-size: cover;
  background-position: bottom center;
  &:hover{
    transform: scale(1.02);
    p{
      color: var(--brand-color-main);
    }
  }
}

.nav-auth{
  background-color: #fff;
  font-size: 0.8rem;
  & ul{
    list-style: none;
    padding: 0;
    gap: 5rem;
    margin: 0;
  }
  & a{
    text-decoration: none;
    color: #141414;
    transition: all .3s ease-in-out;
    &:hover{
      color: var(--brand-color-main);
    }
  }
}

.btn-signout-nav{
  background-color: #000000;
  border: none;
  border-radius: 50%;
  color: #fff;

}

#auth-menu{
  & a{
    color: #141414 !important;
    &:hover{
      color: var(--brand-color-main) !important;
    }
  }
  & .syd-button {
    background-color: #000 !important;
    &:hover {
      background-color: #333 !important;
      color: var(--brand-color-main) !important;
  }
  }
}

.logo-nav-indastria-mob-menu{
  width: 150px;
}

@media (max-width: 480px) {
  .logo-nav {
    width: 80px;
    &.large{
      width: 130px;
    }
  }
  .logo-nav-indastria{
    height: 60px;
    width: auto;
  }
  .btn-signout-nav{
    aspect-ratio: 1/1;
  }
}
