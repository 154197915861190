.color-picker-container {
  display: inline-flex;
  align-items: center;
  padding: 4px;
  border: 1px solid #bfc9d9;

  input[type="color"] {
    margin-right: 8px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    &::-webkit-color-swatch-wrapper {
      padding: 0;
      width: 30px;
      height: 30px;
    }
    &::-webkit-color-swatch {
      border: 1px solid #bfc9d9;
      padding: 0;
    }
  }

  input[type="text"] {
    border: none;
    width: 100%;
  }
}

.card-dash-admin {
  // border: 2px solid;
  background-color: #fff;
  filter: drop-shadow(1px 1px 2px #c3c3c3);
}

.box-example-card-style {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(../../assets/hero_blue.png);
  // background: url(../src/assets/hero_blue.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  overscroll-behavior: none;
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
}

.example-card-style {
  background-color: #fff;
  max-width: 100%;
}

.btn-example {
  padding: 0.5rem 1rem;
  border: none;
}

.file-upload-container {
  border: 1px solid #ddd;
}

.file-input {
  margin-bottom: 10px;
}

.upload-btn {
  padding: 10px 15px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.upload-btn:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3em;
  height: 1.5em;

  & input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background: #fece2f;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #fece2f;
    }

    &:checked + .slider:before {
      transform: translateX(1.5em);
    }
  }
}

.slider {
  position: absolute;
  cursor: pointer;
  inset: 0;
  background: #bababa;
  border-radius: 50px;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);

  &:before {
    position: absolute;
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
    inset: 0;
    background-color: #f6f6f6;
    border-radius: 50px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.4);
    transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
}

.btn-dash-global{
  background: #fff;
  border: 2px solid #000;
  color: #000;
}

.box-admin-office{
  background: #fff;
  border: 2px solid #000;
  background-color: #fcfcfc;
}

.btn-admin-delete-office{
  background-color: #d20000;
  color: #fff;
  border-radius: 0.4rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
  border: none;
  border-radius: 50%;
  &:hover{
    background-color: #ee0000;
  }
}

.btn-admin-add-office{
  background-color: #fece2f;
  color: #000;
  border-radius: 0.4rem;
  // width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all .3s ease-in-out;
  border: none;
  &:hover{
    background-color: #e7b925;
  }
}

code{
  font-size: 0.8em;
  line-height: 1em;
  font-weight: 400 !important;
  background-color: #d0d0d5;
  color: #333;
  padding: 4px 6px;
  font-family: 'Roboto Mono', monospace;
}

.tab-sx-side{
  border: 2px solid #000;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  &:hover{
    background-color: #e2e2e2;
  }
  &.selected{
    background-color: #fece2f !important;
  }
}

.container-columns{

}

.side-sx{
  height: max-content;
}

.side-dx{
  overflow-y: scroll;
}
