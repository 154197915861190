.main-box-pa{
  aspect-ratio: 1/1;
}

.partner-box {
  border: 1px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  bottom: 0;
  margin: auto;
  & img {
    filter: grayscale(1);
    &:hover{
      filter: grayscale(0);
    }
  }
  // &:hover {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   transform: scale(1.05);
  //   z-index: 1;
  //   border-color: var(--sydBlack);
  //   & img {
  //     filter: grayscale(0);
  //   }
  //   .partner-text{
  //     opacity: 1;
  //     visibility: visible;
  //     font-size: 1rem;
  //   }
  // }
}

.pad-box-ref{
  padding: 2.5rem;
}

.partner-text{
  font-size: 0px;
  opacity: 0;
  visibility: hidden;
  top: 100%;
  top: calc(100% - 2rem);
  background-color: #f7f7f7;
  z-index: 1;
}


.flip-card {
  background-color: transparent;
  width: 100%;
  height: 100%;
  // perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front, .flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  background-color: #bbb;
  color: black;
}

/* Style the back side */
.flip-card-back {
  background-color: dodgerblue;
  color: white;
  transform: rotateY(180deg);
}

.popover{
  background: #fff;
  border-radius: 0;
  border-top: 3px solid var(--brand-color-main);
  font-family: inherit;
  max-width: 35%;
  background-color: #000;
  padding: 1rem;
}

.popover .popover-arrow::before, .popover .popover-arrow::after {
  border-color: #000;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

@media (max-width: 480px) {
  #popover-trigger-hover-focus{
    display: none;
  }
  .partner-box {
    & img {
      filter: grayscale(0);
    }
  }
}
