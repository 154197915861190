// .hero-image {
//   width: 100vw;
//   height: 100vh;
//   object-fit: cover;
// }

.hero-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
}

.swiper-slide img {
  display: block;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.img-slide-hero {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: -webkit-transform 9.5s;
  transition: transform 9.5s;
  transition: transform 9.5s, -webkit-transform 9.5s;
  transition-delay: 0.5s;
}

.slide-overlay {
  // background: #0000007c;
  // background: linear-gradient(24deg, rgba(0, 0, 0, .35), transparent);
  background: linear-gradient(224deg, transparent 45.5%, rgba(13, 17, 20, 0.9));
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  // &::after{
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background: linear-gradient(180deg, transparent 45.5%, rgba(13, 17, 20, 0.9));
  //   background-blend-mode: overlay;
  // }
}

.col-slide-hero {
  width: 60%;
  height: 100%;
  h2 {
    font-size: 3.75rem;
  }
}

.slide-hero-title {
  background: transparent;
  color: #fff;
  padding-bottom: 0;
  position: relative;
  top: 1.25rem;
  transition: top 0.8s, opacity 0.8s;
  opacity: 0;
  overflow: hidden;
  word-break: break-word;
}

.box-action-hero {
  color: #fff;
  left: -10%;
  opacity: 0;
  position: relative;
  transition: left 0.5s, opacity 0.5s;
  transition-delay: 0.6s;
  font-size: 2rem;
  width: max-content;
  transition: color .3s ease-in-out;
  &:hover{
    color: var(--dark-mode-title);
  }
}

.box-arrow-hero {
  height: 2rem;
  display: flex;
}

.swiper-slide-active {
  .slide-home-hero .img-slide-hero {
    transform: scale(1.2);
  }
  .slide-hero-title {
    opacity: 1;
    top: 0;
  }
  .box-action-hero {
    opacity: 1;
    left: 0;
  }
}

.hero-progressbar {
  height: 5px;
  background: var(--brand-color-main);
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  right: 100%;
  animation: scrollbar-story 10s linear forwards;
}

@keyframes scrollbar-story {
  from {
    right: 100%;
  }
  to {
    right: 0;
  }
}

.btn-scroll-down{
  border: 1px solid;
  color: var(--brand-color-main);
  height: 3rem;
  width: 3rem;
  position: absolute;
  z-index: 5;
  bottom: 1rem;
  right: 1rem;
  display: flex;
  padding: 0.5rem;
  border-radius: 50%;
  margin: auto;
  cursor: pointer;
  animation: animate-scroll-btn 2s linear infinite;
}

@keyframes animate-scroll-btn {
  0% {
    bottom: 1.5rem;
  }
  50% {
    bottom: 1rem;
  }
  100% {
    bottom: 1.5rem;
  }
}

@media (max-width: 480px) {
  .swiper-slide img{
    height: 100vh;
    width: auto;
  }
  .col-slide-hero { width: 100%; }
  .btn-scroll-down {
    right: 0;
    left: 0;
  }
  .col-slide-hero {
    h2 {
      font-size: 2.5rem;
    }
  }

}

// background: linear-gradient(24deg, rgba(0, 0, 0, .35), transparent);