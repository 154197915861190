.header-services-detail {
  background-color: var(--syd-dark-color);
  min-height: 35rem;
  z-index: 0;
  &.area-service {
    min-height: 25rem;
  }
}

.box-desc-serv {
  background-color: var(--opacityDarkBg);
  margin: 1rem;
  padding: 3rem 10rem 3rem 2rem;
}

.link-service h4 {
  width: max-content;
  transition: all 0.3s ease-in-out;
  &:hover {
    color: #a6a6a6;
  }
}

.box-sub-service {
  width: 70%;
}

.box-flex{
    display: flex;
}

.box-main-text-desc-service{
  padding: 2rem 12rem;
}
.main-text-desc-service{
  color: #f7f7f7;
  font-size: 2.5rem;
  // font-size: 42px;
  line-height: normal;
  text-align: center;
  letter-spacing: 1px;
}

@media (max-width: 480px) {
  .box-sub-service {
    width: 100%;
  }
  .box-desc-serv{
    margin: 0;
    padding: 1.5rem;
  }
  .box-flex{
    display: block;
  }
}

.box-more-info-service{
  margin: 1rem;
  background-color: var(--dark-mode-background);
}

.text-more-info{
  color: var(--dark-mode-title);
}

.btn-more-info-service{
  background-color: var(--dark-mode-btn-background);
  color: var(--dark-mode-btn-text);
}

@media (max-width: 480px) {
  .box-more-info-service{
    width: 100%;
    margin: 0;
  }
}