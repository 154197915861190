.box-home-insights {
  aspect-ratio: 1/1;
}

.image-home-insights {
  height: 100%;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center;
}

.syd-grid {
  grid-column-gap: 0.5rem;
  grid-row-gap: 0.5rem;
  -moz-column-gap: 0.5rem;
  column-gap: 0.5rem;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  row-gap: 0.5rem;
  column-gap: 0.5rem;
  padding: 0.5rem;
}

.grid-card-s-0 {
  grid-area: 1/1/3/5;
}

.grid-card-s-1 {
  grid-area: 1/5/2/9;
}
.grid-card-s-2 {
  grid-area: 1/9/2/13;
}
.grid-card-s-3 {
  grid-area: 2/5/3/9;
}
.grid-card-s-4 {
  grid-area: 2/9/3/13;
}

.grid-card-s {
  grid-column: span 4;
}

.insights-card {
  display: block;
  height: 100%;
  overflow: hidden;
  padding: 1rem;
  position: relative;
  width: 100%;
  height: 15rem;
  background: #000;
  text-decoration: none;
  border-radius: 5px;
  &:hover {
    .insights-image {
      transform: scale(1.02);
    }
    .insights-title-card {
      &::before {
        width: 100%;
      }
    }
  }
  &.card-l {
    display: flex;
    height: 100%;
  }
}

.insights-card-prev {
  display: block;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
  text-decoration: none;
  border-radius: 10px;
  &:hover {
    .insights-image {
      transform: scale(1.02);
    }
    .insights-title-card {
      &::before {
        width: 100%;
      }
    }
  }
  &.card-l {
    display: flex;
    height: 100%;
  }
  &::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, transparent 45.5%, rgba(13, 17, 20, 0.9));
    background-blend-mode: overlay;
  }
}

.insights-image {
  height: 100%;
  left: 0;
  -o-object-fit: cover;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

.insights-card-content {
  display: flex;
  flex-flow: column;
  gap: 1rem;
  height: 100%;
  justify-content: flex-end;
  position: relative;
}

.insights-card-body {
  display: flex;
  flex-direction: column;
}

.insights-title-card {
  color: #f6f6f6;
  font-size: 1.5rem;
  &::before {
    content: "";
    width: 0px;
    height: 2px;
    display: block;
    background: var(--brand-color-main);
    transition: 0.5s;
  }
}

.arrow-all {
  fill: none;
  // stroke: var(--sydBlack);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2px;
}

.icon-arrow {
  height: 15px;
  width: auto;
}

.ref-syd-nav {
  text-decoration: none;
  margin-left: auto;
  padding: 1rem 0;
  &:hover {
    color: var(--brand-color-main);
    .arrow-all {
      stroke: var(--brand-color-main);
    }
  }
}

@media (max-width: 480px) {
  .syd-grid {
    display: flex;
    flex-direction: column;
  }
  .insights-card {
    height: auto !important;
    padding-top: 4rem;
  }
}
