.box-area-lbl, .box-gl-admin, .box-item-gl, .accordion-button{
  border: 2px solid var#141414;
}

.lbl-area{
  background-color: #141414;
  width: max-content;
  color: #f6f6f6;
}

.box-item{
  border-bottom: 1px solid #a8a8a8;
}

.accordion-button:not(.collapsed){
  background-color: #fece2f !important;
  & span{
    color: #141414 !important;
    border-color: inherit;
  }
}

.accordion-button{
  border-radius: 0 !important;
  box-shadow: none !important;
  border: 2px solid #141414 !important;
}
