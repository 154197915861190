.box-logo-client-stories {
  width: 30%;
  aspect-ratio: 1/1;
  background-color: var(--sydWhite);
}

.img-clients-stories {
  width: 100%;
}

.tile-client-story {
  &:hover {
     .client-story-tile-img {
      transform: scale(1.03);
    }
  }
}

.bkg-tile-client-story{
  // background: #000000a3;
}

.bg-client-stories {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.65;
    z-index: 1;
  }
}

.body-stories-sect {
  z-index: 2;
}

.syd-vertical-box{
  height: 400px;
  max-height: 400px;
  border-radius: 10px;
}

.syd-vertical-box-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-basis: 100%;
  max-width: 100%;
  padding: 1rem;
}